import React, { createContext, useRef } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import UserPool from '../Statics'

const UserContext = createContext()

const Account = ({ children }) => {
  const scrollRef = useRef({
    scrollPos: 0,
  })

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser()
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject()
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err)
                } else {
                  const results = {}
                  for (let attribute of attributes) {
                    const { Name, Value } = attribute
                    results[Name] = Value
                  }
                  resolve(results)
                }
              })
            })
            resolve({
              user,
              ...session,
              ...attributes,
            })
          }
        })
      } else {
        reject()
      }
    })
  }

  const logout = () => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.signOut()
    }
  }

  const authenticate = async (Email, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: Email,
        Pool: UserPool,
      })

      const authDetails = new AuthenticationDetails({
        Username: Email,
        Password,
      })

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          console.log('onSuccess:', data)
          resolve(data)
        },

        onFailure: err => {
          console.error('onFailure:', err)
          reject(err)
        },

        newPasswordRequired: data => {
          console.log('newPasswordRequired:', data)
          resolve(data)
        },
      })
    })
  }

  return (
    <UserContext.Provider
      value={{ scrollRef, authenticate, getSession, logout }}
    >
      {children}
    </UserContext.Provider>
  )
}

export { Account, UserContext }
