import '../styles/verification.css'
import '../styles/globals.css'
import '../styles/custmize.css'
import '../styles/monaco.css'
import '../styles/editor.css'
import '../styles/typing.css'
import { ThemeProvider } from 'next-themes'
import Layout from '../components/layout'
import { Provider } from 'react-redux'
import { store } from '../redux/store'
import { useRouter } from 'next/router'
import Meta from '../components/Meta'
import { Account, UserContext } from '../components/UserContext'
import { useRef } from 'react'

if (typeof window !== 'undefined') {
  require('bootstrap/dist/js/bootstrap')
}

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const pid = router.asPath
  const scrollRef = useRef({
    scrollPos: 0,
  })

  return (
    <>
      <Meta title='Home' />

      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute='class'>
          <Account>
            {pid === '/login' ? (
              <Component {...pageProps} />
            ) : (
              <Layout>
                <Component {...pageProps} />
              </Layout>
            )}
          </Account>
        </ThemeProvider>
      </Provider>
    </>
  )
}

export default MyApp
