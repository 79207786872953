import { CognitoUserPool } from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: 'ap-southeast-2_a9GAEogJl',
  ClientId: '6nluh2k3gun69vmh1kn4qqgp3c',
}

export const codeFormat = [
  'XML',
  'PHP',
  'C#',
  'C++',
  'Razor',
  'Markdown',
  'Diff',
  'Java',
  'VB',
  'CoffeeScript',
  'Handlebars',
  'Batch',
  'Pug',
  'F#',
  'Lua',
  'Powershell',
  'Python',
  'Ruby',
  'SASS',
  'R',
  'Objective-C',
  'TypeScript',
  'JavaScript',
  'CSS',
  'LESS',
  'SCSS',
  'JSON',
  'HTML',
]

export const origin = [
  'human',
  'llama2-7b',
  'llama2-13b',
  'zephyr-7b-alpha',
  'qwen-14b',
  'GPT-3.5',
  'claude-v1',
  'wizardlm-13b',
  'gpt-3.5-turbo',
  'RWKV-4-Raven-14B',
  'vicuna-7b',
  'claude-instant-v1',
  'fastchat-t5-3b',
  'palm-2',
  'alpaca-13b',
  'dolly-v2-12b',
  'gpt-4',
  'gpt4all-13b-snoozy',
  'mpt-7b-chat',
  'koala-13b',
  'chatglm-6b',
  'guanaco-33b',
  'llama-13b',
  'vicuna-13b',
  'stablelm-tuned-alpha-7b',
  'oasst-pythia-12b',
]

export const examplePythonCode = `
from python_utils import readlines

def bit_array_to_number(bit_array: list[bool]) -> int:
    """Convert a binary bit array to an integer."""
    return int(f'0b{"".join([str(int(bit)) for bit in bit_array])}', 2)

def main():
    # Read input and remove leading/trailing whitespace
    input = [line.strip() for line in readlines()]
    
    # Get the number of bits in the first input line
    num_bits = len(input[0])

    # Initialize bit frequency dictionary
    bit_frequency = {
        '0': [0] * num_bits,
        '1': [0] * num_bits,
    }

    # Count frequency of bits by position
    for binary_number in input:
        for idx, bit in enumerate(binary_number):
            bit_frequency[bit][idx] += 1

    # Generate gamma_bit_array
    gamma_bit_array = [
        bit_frequency['1'][bit_idx] > bit_frequency['0'][bit_idx]
        for bit_idx in range(num_bits)
    ]

    # Calculate gamma_rate and epsilon_rate
    gamma_rate = bit_array_to_number(gamma_bit_array)
    epsilon_rate = bit_array_to_number([not bit for bit in gamma_bit_array])

    # Print the product of gamma_rate and epsilon_rate
    print(gamma_rate * epsilon_rate)

if __name__ == '__main__':
    main()
`

export default new CognitoUserPool(poolData)
