import Head from 'next/head'

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title} || Startix</title>
        <link rel='icon' href='/favicon.png' />
        <meta name='description' content={desc} />
        <meta name='keyword' content={keyword} />
      </Head>
    </div>
  )
}

Meta.defaultProps = {
  title: 'Startix | An AI platform for all your business needs',
  keyword:
    'gpt, detect, gpu, ai, artificial intelligence, machine learning, ml, nlp, natural language processing, text generation, text classification, text summarization, text translation, text extraction, text analysis, text analytics, text similarity, text similarity detection, text similarity dete',
  desc: 'Startix is an AI platform for all your business needs.',
}

export default Meta
